import ReactGA from "react-ga4";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.scss";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./pages/home";
import Events from "./pages/events";
import About from "./pages/about";
import Pastors from "./pages/pastors";
import Gallery from "./pages/gallery";
import NoMatch from "./pages/nomatch";
import FAQs from "./pages/faq";
import Offering from "./pages/offering";
import { useEffect, useLayoutEffect } from "react";
import Kids from "./pages/ministries/pages/kids";
import Outreach from "./pages/ministries/pages/outreach";
import Contacts from "./pages/contacts";
import News from "./pages/news";
import PDFView from "./pages/pdf";
import History from "./pages/history";
import Ministries from "./pages/ministries";
import SermonsPage from "./pages/sermons";
import TechArts from "./pages/ministries/pages/tech-arts";
import PrayerCare from "./pages/ministries/pages/prayer-care";
import Music from "./pages/ministries/pages/music";
import CottagePrayerMeeting from "./pages/ministries/pages/cottage-prayer";
import BibleStudy from "./pages/ministries/pages/bible-study";
import HowToUseMyImages from "./pages/disclaimers/how-to-use-my-images";
import FallFestival from "./pages/special-pages/fall-festival";
import SiteMusic from "./components/siteMusic";

function App() {
  let location = useLocation();
  const allowSiteMusic = process.env.REACT_APP_ALLOW_SITE_MUSIC === "true";

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    ReactGA.initialize([
      { trackingId: "G-4NZQYLRVF9" },
      { trackingId: "AW-16712956940" },
    ]);
  }, []);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
    document.body.setAttribute("data-page", location.pathname);
  }, [location]);

  return (
    <>
      <Header></Header>
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="events" element={<Events />} />
        <Route path="gallery" element={<Gallery />} />
        <Route path="pastors" element={<Pastors />} />
        <Route path="faq" element={<FAQs />} />
        <Route path="donation" element={<Offering />} />
        <Route path="ministries" element={<Ministries />} />
        <Route path="kids" element={<Kids />} />
        <Route path="outreach" element={<Outreach />} />
        <Route path="tech-arts" element={<TechArts />} />
        <Route path="prayer-care" element={<PrayerCare />} />
        <Route path="music" element={<Music />} />
        <Route path="cottage-prayer" element={<CottagePrayerMeeting />} />
        <Route path="bible-study" element={<BibleStudy />} />
        <Route path="contacts" element={<Contacts />} />
        <Route path="news" element={<News />} />
        <Route path="pdf" element={<PDFView />} />
        <Route path="history" element={<History />} />
        <Route path="sermons" element={<SermonsPage />} />
        <Route path="how-to-use-my-images" element={<HowToUseMyImages />} />
        <Route path="fall-festival-2024" element={<FallFestival />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
      <Footer></Footer>
      {allowSiteMusic && <SiteMusic></SiteMusic>}
    </>
  );
}

export default App;
