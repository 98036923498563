import React from "react";
import ReactGA from "react-ga4";
import useDocumentTitle from "../../../../components/useDocumentTitle";

export default function Music() {
  useDocumentTitle("Music Ministry");
  ReactGA.send({ hitType: "pageview", page: "/music" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/music-bg.jpg)",
          backgroundPosition: "bottom",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Music Ministry</h1>
                  {/* <p className="lead">
                    Songs, Music and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
          <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Choir</h2>
          </header>
          <div className="row gy-4">
            <div className="col">
            <figure class="figure float-md-start imgshadow me-md-4 mb-4">
                <img
                  src="img/music-1.jpg"
                  class="figure-img img-fluid"
                  alt="Music"
                  style={{ maxWidth: 450, width: '100%'}}
                ></img>
                <figcaption class="figure-caption text-center">
                  Choir of Love of Christ CSI Church singing hymns
                </figcaption>
              </figure>
              <p>
                At Love of Christ CSI Church, our Music Ministry is more than
                just singing hymns. We are a vibrant community of singers and
                musicians dedicated to enriching the worship experience and
                glorifying God through our talents. Our mission is to:
              </p>
              <ul>
                <li>
                  <b>Lead the congregation in worship:</b> We guide our
                  community in singing with reverence to God, creating an
                  atmosphere of prayer and praise.
                </li>
                <li>
                  <b>Empower through participation:</b> We provide opportunities
                  and encourage every member of the congregation to share their
                  musical gifts and talents, fostering a sense of belonging and
                  shared purpose.
                </li>
              </ul>
              <p>
                We are grateful for the many ways God has blessed our Music
                Ministry:
              </p>
              <ul>
                <li>
                  <b>Consistent dedication:</b> Our Music Ministry team
                  diligently practices and prepares hymns, songs, and music for
                  every Sunday worship service, ensuring a spiritually uplifting
                  experience for all.
                </li>
                <li>
                  <b>Enhancing special events:</b> We orchestrate special songs
                  for occasions like Easter and Christmas, adding more joy and
                  meaning to these celebrations through carefully chosen music
                  and heartfelt performances.
                </li>
                <li>
                  <b>Embracing contemporary sounds:</b> We are actively
                  incorporating contemporary and regional songs into our Matins
                  Service, invigorating and energizing the congregation to
                  praise and worship God in spirit and truth, while honoring our
                  rich musical heritage.
                </li>
              </ul>
              <figure class="figure float-md-end imgshadow ms-md-4 mb-4">
                <img
                  src="img/music-2.jpg"
                  class="figure-img img-fluid"
                  alt="Music"
                  style={{ maxWidth: 390, width: '100%'}}
                ></img>
                <figcaption class="figure-caption text-center">
                  Church Organ at the Love of Christ CSI Church
                </figcaption>
              </figure>
              <p>
                Looking ahead, we are excited to continue growing and serving
                our church community. Our goals include:
              </p>
              <ul>
                <li>
                  <b>Nurture talent:</b> We aim to identify and provide
                  opportunities to foster and grow the music and singing talents
                  of every interested member at Love of Christ CSI Church,
                  through workshops, mentorship programs, and individual
                  support.
                </li>
                <li>
                  <b>Elevate worship:</b> We continually strive to edify and
                  enhance our worship experience through music and singing,
                  exploring new musical styles, incorporating diverse
                  instruments, and creating opportunities for congregational
                  participation.
                </li>
              </ul>
              <p>
                We thank and praise God for blessing us with many gifted
                musicians and singers. We are deeply grateful for their
                sacrificial time and effort in this wonderful ministry. Their
                dedication and passion inspire us all.
              </p>

              <h4>Join Us!</h4>
              <p>
                If you are interested in sharing your musical gifts with our
                community, we invite you to join our Music Ministry. Whether you
                are a vocalist, instrumentalist, or simply enjoy singing, there
                is a place for you in our choir or ensemble.
              </p>
              <p>Contact: <a href="mailto:music.leader@loccsi.org">music.leader@loccsi.org</a></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
